import * as React from "react"

const Footer = () => (
  <footer className='p30 flex align-center pb20 m-small m-wrap m-center m-col-reverse z-3'>
    <p className='m0 op-50 m-100 z-3 fade--in delay-100' data-sal>©{new Date().getFullYear()} Anthony K Do. All Rights Reserved. <a className='link m-show m-mt10' href='https://carlbeaverson.com' rel='noreferrer' target='_blank'>Website Credit</a></p>
    <div className='mla social flex align-center m-100 m-mb20 m-justify z-3 fade--in delay-100' data-sal>
      <a className='op-link m-hide' href='https://carlbeaverson.com' rel='noreferrer' target='_blank'>Website Credit</a>
      <a className='op-link' href='https://www.instagram.com/anthonykdo' rel='noreferrer' target='_blank' aria-label='instagram'><div className='icon instagram'/></a>
      <a className='op-link' href='https://www.youtube.com/@AnthonyDo'  rel='noreferrer' target='_blank' aria-label='youtube'><div className='icon youtube'/></a>
    </div>
  </footer>
)

export default Footer
