import * as React from "react"

const Header = ({ siteTitle }) => (
  <header className='masthead flex'>
    <div className='socials mla p20'>
      <a className='op-link reverse' href='https://www.instagram.com/anthonykdo' rel='noreferrer' target='_blank' aria-label='instagram'><div className='icon instagram'/></a>
      <a className='op-link reverse' href='https://www.youtube.com/@AnthonyDo'  rel='noreferrer' target='_blank' aria-label='youtube'><div className='icon youtube'/></a>
    </div>
  </header>
)

export default Header
